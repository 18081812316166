import React from "react";
import { Link } from "react-router-dom";

const CallToAction = () => {
  return (
    <>
      <section className="container bg-[#121212] mx-auto">
        <div className="mx-auto py-20">
          <div className="grid justify-items-center">
            <div className="py-12 text-center">
              <div className="text-4xl sm:px-4">
                <h3 className="text-[#FFFFFF] uppercase font-bold">
                  Work with tech experts
                </h3>
              </div>
              <div className="grid justify-items-center py-12">
                <Link
                  to="/contact"
                  className="py-4 px-16 text-xl font-medium bg-[#FFFFFF] text-[#121212] hover:text-[#FFFFFF] hover:bg-[#121212] hover:border-2 hover:border-[#FFFFFF] transition duration-300"
                >
                  Let's talk
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CallToAction;
