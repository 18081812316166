import React from 'react'

import ReactGA from 'react-ga4'


import MainAbout from '../components/About/MainAbout'
import AboutCta from '../components/About/AboutCta'


const About = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/about",
    title: "About",
  });

  return (
    <div>
      <MainAbout />
      {/* <Teams /> */}
      <AboutCta />
    </div>
  )
}

export default About