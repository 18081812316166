import React from 'react'
import { Link } from 'react-router-dom'


import blackicon from '../assets/blackicon.svg'

const Hero = () => {
    return (
        <>
            <div className='lg:py-20 bg-[#FFFFFF]'>
                <section className="container mx-auto">
                    <div className="mx-auto p-6">
                        <div className="lg:px-16 md:px-12 sm:px-8">
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 py-6">
                                <div className="h-96 rounded-lg bg-[#FFFFFF] order-2 lg:order-1">
                                    <div className="p-4">
                                        <h2 className="text-3xl sm:text-4xl md:text-5xl uppercase font-bold text-[#121212]">
                                            Turning Ideas Into Innovations.
                                        </h2>
                                    </div>
                                    <div className="p-4" data-aos="fade-up">
                                        <p className="font-medium leading-relaxed">
                                            We are a mobile and web development firm that helps develop ideas into comprehensive solutions.
                                            We combine creativity, expertise, and product development approach to deliver solutions for users and businesses. <br />
                                        </p>
                                    </div>
                                    <div className="p-2 flex">
                                        <Link to="/contact" className="inline-flex justify-center items-center gap-x-3 text-center text-[#FFFFFF] bg-[#03045E] shadow-lg shadow-transparent hover:shadow-blue-700/50 border border-transparent text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white py-3 px-6">
                                            Contact Us
                                        </Link>
                                    </div>
                                </div>
                                <div className="flex justify-center p-4 rounded-lg bg-[#FFFFFF] order-1 lg:order-2">
                                    <img className='h-48 sm:h-64 md:h-80 lg:h-96' src={blackicon} alt="Homeicon" loading="lazy"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Hero
