import React, { useEffect, useState } from 'react';

const CookieConsent = () => {
  const [consent, setConsent] = useState(null);

  useEffect(() => {
    const consent = getCookie('cookieConsent');
    if (!consent) {
      setConsent(false);
    }
  }, []);

  const setCookie = (name, value, days) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
    document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
  };

  const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  const acceptCookies = () => {
    setCookie('cookieConsent', 'true', 365);
    setConsent(true);
  };

  const declineCookies = () => {
    setCookie('cookieConsent', 'false', 365);
    setConsent(true);
  };

  if (consent !== false) return null;

  return (
    <div className="fixed bottom-0 w-full bg-gray-800 text-white p-4 md:p-6 lg:p-8 text-center flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-4">
      <span className="text-sm md:text-base lg:text-lg">
        This website uses cookies to ensure you get the best experience.
      </span>
      <div>
        <button 
          onClick={acceptCookies} 
          className="ml-2 bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded text-sm md:text-base lg:text-lg">
          Accept
        </button>
        <button 
          onClick={declineCookies} 
          className="ml-2 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded text-sm md:text-base lg:text-lg">
          Decline
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
