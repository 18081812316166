import React, { useState } from 'react';

const AccordionItem = ({ title, children, isOpen, onClick }) => {
  return (
    <>
      <div className=" container border-b border-gray-200">
        <button
          className={`w-full py-4 lg:px-16 md:px-12 sm:px-8 text-left flex justify-between items-center focus:outline-none ${isOpen ? 'text-customBlue' : 'text-customBlack'
            }`}
          onClick={onClick}
          aria-expanded={isOpen}
          aria-controls={`accordion-content-${title}`}
        >
          <span className="text-xl lg:text-2xl font-bold">{title}</span>
          <svg
            className={`w-6 h-6 transition-transform duration-300 ${isOpen ? 'transform rotate-180' : ''}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </button>
        <div
          id={`accordion-content-${title}`}
          className={`overflow-hidden transition-all duration-300 ${isOpen ? 'max-h-screen' : 'max-h-0'}`}
        >
          <div className="p-4 lg:px-16 md:px-12 sm:px-8">
            <p className="text-base lg:text-lg font-semibold">{children}</p>
          </div>
        </div>
      </div>
    </>

  );
};

const Accordion = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleItemClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const items = [
    {
      title: 'We Are Customer-Centric',
      content: 'At Batian Technologies, our clients are the core of our business. We customize our technology solutions to address your specific needs, ensuring your business achieves its goals with precision and efficiency.',
    },
    {
      title: 'We Are Transparent',
      content: 'We conduct our business with honesty and transparency, keeping you informed at every project stage. Our clear communication and straightforward processes build trust and foster long-term client partnerships.',
    },
    {
      title: 'We Are Passionate',
      content: 'Our team is driven by a genuine passion for technology and innovation, which reflects in the quality of our work. We strive to deliver innovative solutions that not only meet but exceed your expectations.',
    },
    {
      title: 'We Care About Your Security',
      content: 'We prioritize the security and privacy of your data, implementing robust cybersecurity measures to protect against threats. Our commitment to data security ensures that your information remains safe and confidential at all times.',
    },
    {
      title: 'We Respond Fast',
      content: 'We understand the importance of timely responses and are dedicated to providing prompt support and solutions. Our agile approach allows us to quickly address your concerns and keep your projects on track.',
    },
  ];

  return (
    <div className="p-8 container mx-auto">
      <div className="max-w-2xl lg:max-w-4xl mx-auto mt-8 bg-white rounded-lg px-4 shadow-lg">
        {items.map((item, index) => (
          <AccordionItem
            key={index}
            title={item.title}
            isOpen={openIndex === index}
            onClick={() => handleItemClick(index)}
          >
            {item.content}
          </AccordionItem>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
