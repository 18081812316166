import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Process = () => {
  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);

  return (
    <div className="container mx-auto p-8">
      <div className="lg:px-16 md:px-12 sm:px-8 py-4">
        <p className="text-3xl sm:text-4xl md:text-5xl font-bold text-[#121212]">How We Do It</p>
      </div>
      <div className="px-4 py-8">
        {[
          {
            title: 'Briefing & Planning',
            description1:
              'We engage the client to understand their vision, goals, and requirements for the solution.',
            description2:
              'We also address market research and feasibility studies to create a detailed project plan outlining timelines, resources, and milestones.',
            bgColor: 'bg-[#2541B2]',
          },
          {
            title: 'Design & Prototyping',
            description1:
              'We then address the solution\'s architecture, create wireframes and prototypes to visualize the functionality and user experience.',
            description2:
              'We gather client feedback and make necessary adjustments to ensure alignment with their vision.',
            bgColor: 'bg-[#2F1847]',
          },
          {
            title: 'Development & Iteration',
            description1:
              'We develop the front-end and back-end components, integrating various functionalities.',
            description2:
              'We iterate the development based on client feedback and testing results to refine and improve the solution.',
            bgColor: 'bg-[#470FF4]',
          },
          {
            title: 'Testing & Q. A.',
            description1:
              'We conduct comprehensive testing, identify and fix bugs, ensuring the solution meets quality standards.',
            description2:
              'We iterate the solution based on testing feedback to enhance performance and reliability.',
            bgColor: 'bg-[#F17300]',
          },
          {
            title: 'Deployment and Maintenance',
            description1:
              'We deploy the solution to the intended platform and monitor its performance.',
            description2:
              'We also provide updates and maintenance to address user feedback, fix issues, and add new features.',
            bgColor: 'bg-[#38A700]',
          },
        ].map((item, index) => (
          <div
            key={index}
            className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8 p-4"
            data-aos="fade-down"
          >
            <div className={`h-32 px-4 rounded-lg ${item.bgColor}`}>
              <div className="flex items-center justify-center h-full text-center">
                <span className="text-2xl font-medium text-[#F1F1F1]">{item.title}</span>
              </div>
            </div>
            <div className="lg:col-span-2 flex items-center">
              <div className="px-2 py-4">
                <p className="text-lg font-medium">{item.description1}</p>
                <p className="mt-4 text-lg font-medium">{item.description2}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Process;
