import React, {useEffect} from 'react'

import AOS from 'aos'
import 'aos/dist/aos.css'
import Accordion from './Accordion'

const Why = () => {
  useEffect(() => {
    AOS.init({duration: 2000});
  }, []);

  return (
    <>
      <div className='bg-[#FFFFFF]'>
        <section className="container mx-auto md:mx-auto md:container sm:container" data-aos = "fade-down">
          <div className="mx-auto p-6">
              <div className="lg:px-16 md:px-12 sm:px-8">
                <div className="py-2">
                  <div className="py-4 text-5xl md:text-4xl sm:text-4xl font-bold"> Why Batian Technologies</div>
                </div>
              </div>
            </div>
          </section>

          <div className="py-2 lg:px-16 md-px-12 sm:px-8">
            <Accordion />
          </div>
      </div>
    </>
  )
}

export default Why