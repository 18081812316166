import React from 'react'
import { ReactTyped } from "react-typed";


const Works = () => {
    return (
        <>
            <div className="container bg-gray-100 mx-auto">
                <div className="py-16 px-16 text-center ">
                    <h2 className='text-[#121212] text-4xl font-bold tracking-tight sm:text-6xl"'> We</h2>
                    <div className='lg:px-16 md:px-12 sm:px-8 py-8'>
                        <ReactTyped
                            className='md:text-5xl sm:text-4xl text-xl text-[#121212] font-medium'
                            strings={[' Design UI|UX', ' Develop Web Apps', ' Develop Mobile Apps', 'Develop Custom Software', 'Integrate Automation']}
                            typeSpeed={120}
                            backSpeed={140}
                            loop
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Works