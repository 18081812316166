import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import d2dwhite from '../assets/d2dwhite.svg'


const D2DWork = () => {
    useEffect(() => {
        AOS.init({duration: 2000});
      }, []);
    
  return (
    <>
        <div>
            <section className='bg-[#099D15] py-20' data-aos = "fade-down">
                <div className="container mx-auto md:mx-auto md:container sm:container">
                    <div className="mx-auto p-8">
                        <div className="lg:px-16 md:px-12 sm:px-8 py-4">
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 py-6">
                                <div className="text-[#F1F1F1]">
                                    <div className="">
                                        <h2 className="lg:text-5xl md:text-5xl sm:text-5xl uppercase font-bold">D2D</h2>
                                    </div>
                                    <div className="py-2">
                                        <p className="font-medium text-xl leading-normal"> User-centric scheduling pick-up and delivery mobile app. </p>
                                    </div>
                                    <div className="py-6">
                                        <p className="font-medium leading-relaxed">
                                            D2D stands for Door to Door. It is a mobile app that enables users schdeule pick-ups and delivery of goods. <br />
                                            It adds convinience for users and enables for live tracking.
                                        </p>
                                    </div>
                                    <div className="py-6 font-bold uppercase leading-relaxed flex justify-start">
                                        <div className="py-2 mr-2">
                                            Courier
                                        </div>
                                        <div className="py-2 mr-2">
                                            Logistics
                                        </div>
                                        <div className="py-2 mr-2">
                                            On Time
                                        </div>
                                    </div>
                                    <div className="py-4">
                                        <button className='py-2 px-4 border border-lg border-[#FFFFFF] text-[#FFFFFF] hover:bg-[#FFFFFF] hover:text-[#099D15]' > More Details </button>
                                    </div>
                                </div>
                                <div className="rounded-lg">
                                    <div className="p-4 grid items-center">
                                        <img className='h-96 w-96 rounded-md hover:shadow-lg' src={d2dwhite} alt="d2dwhite" loading="lazy"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </section>
        </div>
    </>    

  )
}

export default D2DWork