import React from 'react'
import { Link } from 'react-router-dom'

const CtaWork = () => {
  return (
    <>
        <section className="container py-20 mx-auto md:mx-auto md:container sm:container" data-aos = "fade-down">
            <div className="mx-auto p-6">
                <div className="lg:px-16 md:px-12 sm:px-8">
                    <div className="py-2">
                        <div className="py-4 text-5xl md:text-4xl sm:text-5xl font-bold"> Got an idea in mind?</div>
                        <div className="py-4 text-xl  font-medium">Hit us up. Lets work together.</div>
                    </div>
                    <div className="py-4">
                        <Link to ="/contact" className='py-2 px-4 border border-md bg-[#121212] text-[#FFFFFF]'>Contact Us</Link>
                    </div>
                </div>
            </div>
        </section>
    </>

  )
}

export default CtaWork