import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

const TechStack = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);


    return (
        <>
            <div className='pt-8 bg-[#FFFFFF]'>
                <section className="container mx-auto " data-aos="fade-down">
                    <div className="mx-auto px-6 py-2">
                        <div className="lg:px-16 md:px-12 sm:px-8">
                            <div className="py-6">
                                <div className="rounded-lg bg-[#FFFFFF] order-2 lg:order-1">
                                    <h2 className="text-2xl sm:text-4xl md:text-4xl font-bold text-[#121212] space-y-2 leading-loose">
                                        Tech Stack.
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <section className="container mx-auto pb-12 " data-aos="fade-up">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 md:gap-8 mg:grid-cols-2 lg:gap-8 px-8">
                    <div className="rounded-lg py-4 ">
                        <div className="lg:px-16 md:px-12 sm:px-8 ">
                            <div className="px-8 py-4">
                                <h3 className="text-xl font-bold text-[#121212]">Mobile App</h3>
                                <p className="py-4 leading-loose ">
                                    We build android, iOS , and cross-platform mobile applications.
                                    We follow design guidelines for both platforms to build apps that are featured in the App Store and Google Play Store.
                                </p>
                            </div>
                            <div className="px-8">
                                <div className="grid justify-items-start space-y-2">
                                    <div className="font-semibold">React Native</div>
                                    <div className="font-semibold">Java</div>
                                    <div className="font-semibold">Dart</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-lg py-4 ">
                        <div className="lg:px-16 md:px-12 sm:px-8 ">
                            <div className="px-8 py-4">
                                <h3 className="text-xl font-bold text-[#121212]">Web App</h3>
                                <p className="py-4 leading-loose ">
                                    We create modern, mobile-friendly progressive web apps. We apply the best practices of building scalable and performant web applications.
                                </p>
                            </div>
                            <div className="px-8">
                                <div className="grid justify-items-start space-y-2">
                                    <div className="font-semibold">React.js</div>
                                    <div className="font-semibold">Next.js</div>
                                    <div className="font-semibold">Tailwind</div>
                                    <div className="font-semibold">Cypress</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container mx-auto pb-12" data-aos="fade-up">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 md:gap-8 mg:grid-cols-2 lg:gap-8 px-8">
                    <div className="rounded-lg py-4 ">
                        <div className="lg:px-16 md:px-12 sm:px-8 ">
                            <div className="px-8 py-4">
                                <h3 className="text-xl font-bold text-[#121212]">Backend</h3>
                                <p className="py-4 leading-loose ">
                                    We prioritize easily maintainable and available applications.
                                    We address backend solutions that handles loads and delivers value to you.
                                </p>
                            </div>
                            <div className="px-8">
                                <div className="grid justify-items-start space-y-2">
                                    <div className="font-semibold">Node.js</div>
                                    <div className="font-semibold">Express.js</div>
                                    <div className="font-semibold">.NET/ C#</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rounded-lg py-4 ">
                        <div className="lg:px-16 md:px-12 sm:px-8 ">
                            <div className="px-8 py-4">
                                <h3 className="text-xl font-bold text-[#121212]">Database</h3>
                                <p className="py-4 leading-loose ">
                                    We choose the best data storage for building your product from a variety of SQL and NoSQL databases.                            </p>
                            </div>
                            <div className="px-8">
                                <div className="grid justify-items-start space-y-2">
                                    <div className="font-semibold">SQL (PostgreSQL and MySQL) </div>
                                    <div className="font-semibold">NoSQL (MongoDb) </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default TechStack