import React from "react";

import ReactGA from 'react-ga4'

import HeroServices from "../components/Services/HeroServices";
import TechStack from '../components/Services/TechStack'
import CtaWork from "../components/Work/CtaWork";
import Approach from "../components/Services/Approach";
import ServicesOutline from "../components/Services/ServicesOutline";

const Services = () => {
    ReactGA.send({
        hitType: "pageview",
        page: "/services",
        title: "Services",
      });
 
    return (
        <>
            <HeroServices />
            <ServicesOutline />
            <TechStack />
            <Approach />
            <CtaWork />
        </>
    );
};

export default Services;
