import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import gach from '../assets/gach.svg'


const Ndai = () => {
    useEffect(() => {
        AOS.init({duration: 2000});
      }, []);
      
  return (
    <>
        <div>
            <section className='bg-[#138808] py-20' data-aos = "fade-down">
                <div className="container mx-auto md:mx-auto md:container sm:container">
                    <div className="mx-auto p-6">
                        <div className="lg:px-16 md:px-12 sm:px-8">
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 py-6">
                                <div className="rounded-lg h-96 text-[#F1F1F1]">
                                    <div className="p-4">
                                        <h2 className="lg:text-5xl md:text-5xl sm:text-5xl uppercase font-bold">Gach</h2>
                                    </div>
                                    <div className="p-4">
                                        <p className="font-medium text-xl leading-normal">Car sales, enquiry, and rental platform.</p>
                                    </div>
                                    <div className="px-4 py-6">
                                        <div className="py-2">
                                            <p className="font-medium leading-relaxed">
                                                Gach is a platform for users to buy, sell, or rent automobiles. <br />
                                                Users access different brands of automobiles.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="px-4 py-6 font-bold uppercase leading-relaxed flex justify-start">
                                        <div className="py-2 mr-2">
                                            Car Hire
                                        </div>
                                        <div className="py-2 mr-2">
                                            Car Sales
                                        </div>
                                        <div className="py-2 mr-2">
                                            CDP 
                                        </div>
                                    </div>
                                </div>
                                <div className="rounded-lg">
                                    <div className="p-4 grid items-center">
                                    <img className='h-96 w-96 rounded-md hover:shadow-lg' src={gach} alt="grace" loading="lazy"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </section>
        </div>
    </>
  )
}

export default Ndai