import React, {useEffect} from "react";
import { Link } from "react-router-dom";

import AOS from 'aos'
import 'aos/dist/aos.css'

const Cta = () => {
  useEffect(() => {
    AOS.init({duration: 2000});
  }, []);

  return (
    <>
      <div className="bg-[#FFFFFF]">
        <section className="container mx-auto md:mx-auto md:container sm:container" data-aos = "fade-down">
          <div className="mx-auto p-6">
              <div className="lg:px-16 md:px-12 sm:px-8">
                <div className="py-2">
                  <div className="py-4 text-5xl md:text-4xl sm:text-4xl font-bold"> Drop us a message</div>
                </div>
              </div>
            </div>
          </section>
      </div>
    {/* Submission Form Section */}
    <div>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 px-4">
          <div className="rounded-lg p-6">
            <form className="lg:px-16 md:px-12 sm:px-8">
              <div className="mb-4">
                <label className="block text-sm font-medium text-[#121212]" htmlFor="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-[#03045E] focus:border-[#03045E]"
                  placeholder="Your name"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-[#121212]" htmlFor="email">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-[#03045E] focus:border-[#03045E]"
                  placeholder="Your email"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-[#121212]" htmlFor="message">
                  How can we help you?
                </label>
                <textarea
                  id="message"
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-[#03045E] focus:border-[#03045E]"
                  placeholder="Your message"
                />
              </div>
              <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  id="privacy"
                  className="h-4 w-4 text-[#03045E] focus:ring-[#03045E] border-gray-300 rounded"
                />
                <label htmlFor="privacy" className="ml-2 block text-sm text-gray-900">
                {/* Add Link to Privacy Policy and Terms and Conditions */}
                  You agree to our <Link to="#" className="text-[#03045E]">Privacy Policy</Link>
                </label>
              </div>
              <button
                type="submit"
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#0000EE] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#03045E]"
              >
                Send
              </button>
            </form>
          </div>

    {/* Contact Information Section */}
    <div className="rounded-lg bg-white p-6">
      <div className="lg:px-16 md:px-12 sm:px-8">
        <div>
          <h3 className="text-lg font-medium text-gray-900">Project or Solution Inquiries</h3>
          <p>We implement your idea</p>
          <Link to="mailto:info@batiantechnologies.com" className="text-[#0000EE]">info@batiantechnologies.com</Link>
        </div>
        <br />
        <div>
          <h3 className="text-lg font-medium text-gray-900">Become a Tech Partner</h3>
          <p>We are looking for Partners</p>
          <Link to="mailto:partners@batiantechnologies.com" className="text-[#0000EE]">partners@batiantechnologies.com</Link>
        </div>
        <br />
        <div>
          <h3 className="text-lg font-medium text-gray-900">Contact Details</h3>
          <p>Nairobi, Kenya</p>
          <p><Link to="tel:+254728763645" className="text-[#0000EE]">+254 728 763 645</Link></p>
          <p><Link to="tel:+254726498594" className="text-[#0000EE]">+254 726 498 594</Link></p>
          <Link to="mailto:info@batiantechnologies.com" className="text-[#0000EE]">info@batiantechnologies.com</Link>
        </div>
      </div>
    </div>
        </div>
      </div>
    </>
  );
};

export default Cta;
