import React, { useState, useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import { IoIosTimer, IoIosCube } from "react-icons/io";


const ServicesOutline = () => {

  useEffect(() => {
    AOS.init({duration: 2000});
  }, []);

  const [activeSection, setActiveSection] = useState('ideation');
  
  return (
    <>
      <section className="container mx-auto">
        <div className="mx-auto p-6">
          <div className="lg:px-16 md:px-12 sm:px-8">
            <div className="py-2">
              <div className="rounded-lg bg-[#FFFFFF] order-2 lg:order-1">
                <div className="px-8">
                  <h2 className="text-2xl sm:text-4xl md:text-4xl font-bold text-[#121212] space-y-2 leading-loose">
                    Our design and development process:                                    
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container mx-auto">
        <div className="p-6">
          <div className="lg:px-16 md:px-12 sm:px-8 py-6">
          <div className="px-8">
            <div className="flex flex-wrap justify-center font-medium items-center space-x-2 mb-8" data-aos = "fade-up">
                <div className="text-center hover:font-bold cursor-pointer m-2" onClick={() => setActiveSection('ideation')}>
                1: Ideation
                </div>
                <div className="text-center hover:font-bold cursor-pointer m-2" onClick={() => setActiveSection('uiuxdesign')}>
                2: UI|UX Design
                </div>
                <div className="text-center hover:font-bold cursor-pointer m-2" onClick={() => setActiveSection('development')}>
                3: Development
                </div>
                <div className="text-center hover:font-bold cursor-pointer m-2" onClick={() => setActiveSection('deploymentanditeration')}>
                4: Deployment and Iteration
                </div> 
            </div>
        </div>

            {/* Ideation */}
            <div id="ideation" className={`${activeSection === 'ideation' ? '' : 'hidden'} grid grid-cols-1 gap-8 lg:grid-cols-3 px-4 py-4 lg:px-8 lg:py-8`}>
              <div className="lg:col-span-2">
                <div className="py-2">
                  <h4 className="font-bold text-xl text-[#121212]">Ideation Stage</h4>
                  <p className="leading-loose mt-4 text-[#121212]">
                    The ideation phase involves collaborating closely with clients to understand their vision, goals, and requirements. 
                    We brainstorm and explore various innovative concepts, ensuring that the final idea aligns with the client’s objectives and market needs. 
                    This phase sets a solid foundation for the entire project, fostering creativity and strategic planning.
                  </p>
                </div>
              </div>
              <div className="space-y-8 lg:space-y-12">
                <div className="flex items-start space-x-4">
                  <IoIosTimer className='text-[#0000EE] h-10 w-10'/>
                  <div>
                    <h4 className="font-bold text-[#121212]">Timeline</h4>
                    <p>1-2 Weeks</p>
                  </div>
                </div>
                <div>
                  <div className="flex items-start space-x-4">
                    <IoIosCube className='text-[#0000EE] h-10 w-10'/>
                    <h4 className="font-bold text-[#121212]">Deliverables</h4>
                  </div>
                  <ul className='list-none space-y-2 mt-2 pl-14'>
                    <li>Project requirements document</li>
                    <li>Initial concept sketches</li>
                    <li>Project scope and objectives</li>
                    <li>Feasibility analysis report</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* UI|UX Design */}
            <div id="uiuxdesign" className={`${activeSection === 'uiuxdesign' ? '' : 'hidden'} grid grid-cols-1 gap-8 lg:grid-cols-3 px-4 py-8 lg:px-8 lg:py-12`}>
              <div className="lg:col-span-2">
                <div className="py-4">
                  <h4 className="font-bold text-xl text-[#121212]">UI|UX Design</h4>
                  <p className="leading-loose mt-4 text-[#121212]">
                  The UI/UX design phase can take 2-4 weeks, allowing for iterative feedback and revisions. 
                  This phase focuses on creating and refining the visual and interactive aspects of the product to ensure optimal user experience.
                  </p>
                </div>
              </div>
              <div className="space-y-8 lg:space-y-12">
                <div className="flex items-start space-x-4">
                  <IoIosTimer className='text-[#0000EE] h-10 w-10'/>
                  <div>
                    <h4 className="font-bold text-[#121212]">Timeline</h4>
                    <p>2-4 Weeks</p>
                  </div>
                </div>
                <div>
                  <div className="flex items-start space-x-4">
                    <IoIosCube className='text-[#0000EE] h-10 w-10'/>
                    <h4 className="font-bold text-[#121212]">Deliverables</h4>
                  </div>
                  <ul className='list-none space-y-2 mt-2 pl-14'>
                    <li>Wireframes and mockups</li>
                    <li>Interactive prototypes</li>
                    <li>Design Specifications and style guides</li>
                    <li>User journey maps</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Development */}
            <div id="development" className={`${activeSection === 'development' ? '' : 'hidden'} grid grid-cols-1 gap-8 lg:grid-cols-3 px-4 py-8 lg:px-8 lg:py-12`}>
              <div className="lg:col-span-2">
                <div className="py-4">
                  <h4 className="font-bold text-xl text-[#121212]">Development Stage</h4>
                  <p className="leading-loose mt-4 text-[#121212]">
                  The development phase varies widely based on project complexity, ranging from 4 to 12 weeks. 
                  During this phase, the design is translated into a working product, with regular updates and milestones to track progress.
                  The stage also provides the first version of a website, webapp, or mobile app.
                  </p>
                </div>
              </div>
              <div className="space-y-8 lg:space-y-12">
                <div className="flex items-start space-x-4">
                  <IoIosTimer className='text-[#0000EE] h-10 w-10'/>
                  <div>
                    <h4 className="font-bold text-[#121212]">Timeline</h4>
                    <p>4-12 Weeks</p>
                  </div>
                </div>
                <div>
                  <div className="flex items-start space-x-4">
                    <IoIosCube className='text-[#0000EE] h-10 w-10'/>
                    <h4 className="font-bold text-[#121212]">Deliverables</h4>
                  </div>
                  <ul className='list-none space-y-2 mt-2 pl-14'>
                    <li>Source code</li>
                    <li>Functional builds</li>
                    <li>API and 3rd Party integration</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Deployment and Iteration */}
            <div id="deploymentanditeration" className={`${activeSection === 'deploymentanditeration' ? '' : 'hidden'} grid grid-cols-1 gap-8 lg:grid-cols-3 px-4 py-8 lg:px-8 lg:py-12`}>
              <div className="lg:col-span-2">
                <div className="py-4">
                  <h4 className="font-bold text-xl text-[#121212]">Deployment and Iteration</h4>
                  <p className="leading-loose mt-4 text-[#121212]">
                  Initial deployment usually takes 2-4 weeks, involving final testing, user acceptance testing (UAT), and going live. 
                  Iteration is an ongoing process, with regular updates based on user feedback and performance metrics, typically occurring in bi-weekly or monthly cycles.
                  </p>
                </div>
              </div>
              <div className="space-y-8 lg:space-y-12">
                <div className="flex items-start space-x-4">
                  <IoIosTimer className='text-[#0000EE] h-10 w-10'/>
                  <div>
                    <h4 className="font-bold text-[#121212]">Timeline</h4>
                    <p>3-8 Weeks</p>
                  </div>
                </div>
                <div>
                  <div className="flex items-start space-x-4">
                    <IoIosCube className='text-[#0000EE] h-10 w-10'/>
                    <h4 className="font-bold text-[#121212]">Deliverables</h4>
                  </div>
                  <ul className='list-none space-y-2 mt-2 pl-14'>
                    <li>Live product</li>
                    <li>Documentation and guides</li>
                    <li>User manuals</li>
                    <li>Performance reports</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ServicesOutline
