import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import software from '../assets/software.gif'


const HeroWork = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <>
            <div className='lg:py-20 bg-[#FFFFFF]'>
                <section className="container mx-auto">
                    <div className="mx-auto p-6">
                        <div className="lg:px-16 md:px-12 sm:px-8">
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 py-6">
                                <div className="h-96 rounded-lg bg-[#FFFFFF] order-2 lg:order-1">
                                    <div className="p-4">
                                        <h2 className="text-3xl sm:text-4xl md:text-5xl uppercase font-bold text-[#121212]">
                                            We design. <br /> We develop. <br /> We deploy.
                                        </h2>
                                    </div>
                                    <div className="p-4">
                                        <p className=" font-medium leading-relaxed">
                                            Different solutions. We turn your ideas into innovations.
                                            We have a comprehensive work process from ideation to final solution. <br />
                                            Check out our recent work.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-center p-4 rounded-lg bg-[#FFFFFF] order-1 lg:order-2">
                                    <img className='h-48 sm:h-64 md:h-80 lg:h-96' src={software} alt="Workgif" loading="lazy"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default HeroWork