import React, { useEffect, useState } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Industries = () => {
  const [selectedIndustry, setSelectedIndustry] = useState('On-Demand Services')

  useEffect(() => {
    AOS.init({ duration: 2000 })
  }, [])

  const industries = [
    { name: 'On-Demand Services', content: 'We understand the need for rapid, reliable, and user-friendly platforms that connect service providers with customers efficiently. Our innovative technologies and customer-centric approach ensure that your on-demand service app or website is highly responsive, secure, and capable of handling real-time interactions, thus enhancing user satisfaction and loyalty.' },
    { name: 'Fintech', content: 'We develop robust software for digital banking, payment gateways, investment platforms, and management solutions. We prioritize regulatory compliance, data security, and seamless user experience, enabling financial institutions and businesses to thrive in a competitive market while maintaining customer trust and satisfaction.' },
    { name: 'Logistics', content: 'Our expertise in software, IoT, and AI-powered tools enables us to develop systems for real-time tracking, route optimization, inventory management, and automated workflows. By implementing our innovative and customized solutions, logistics companies can achieve higher operational efficiency, reduced costs, and improved service delivery.' },
    { name: 'E-commerce', content: 'Our services encompass custom website development, mobile commerce apps, secure payment gateway integration, and advanced analytics. We create seamless, engaging, and secure shopping experiences that improve customer engagement and conversion rates. We leverage our expertise to ensure that businesses can achieve scalable growth and competitive advantage in the digital marketplace.' },
    { name: 'Creative', content: 'We offer custom software designed for artists, designers, and content creators, enabling them to showcase their work, collaborate in real-time, and reach broader audiences. Our solutions prioritize user experience, aesthetic design, and functionality, ensuring that creative professionals have the tools they need to bring their visions to life and connect with their audiences effectively.' }
  ]

  return (
    <>
      <div className='bg-[#FFFFFF]'>
        <section className="container mx-auto md:mx-auto md:container sm:container" data-aos="fade-down">
          <div className="mx-auto p-6">
            <div className="lg:px-16 md:px-12 sm:px-8">
                <div className="py-4 text-5xl md:text-4xl sm:text-5xl font-bold">Industries</div>
            </div>
          </div>
        </section>

        <div className="pt-6 pb-16 lg:px-16 md:px-12 sm:px-8">
          <div className='grid grid-cols-1 gap-4 lg:grid-cols-3 md:grid-cols-2 lg:gap-8 md:gap-6 p-4'>
            <div id='leftcontent' className="rounded-lg text-xl text-[#121212]">
              <div className="p-2">
                {industries.map((industry, index) => (
                  <h2
                    key={index}
                    className={`cursor-pointer py-2 ${selectedIndustry === industry.name ? 'font-bold bg-[#04F06A]' : 'hover:font-bold active:bg-[#04F06A]'}`}
                    onClick={() => setSelectedIndustry(industry.name)}
                  >
                    {industry.name}
                  </h2>
                ))}
              </div>
            </div>
            <div id='rightcontent' className="h-32 rounded-lg lg:col-span-2 pb-8">
              {industries.map((industry, index) => (
                <div key={index} className={`px-4 ${selectedIndustry === industry.name ? 'block' : 'hidden'}`}>
                  <h3 className="font-bold text-xl py-2">{industry.name}</h3>
                  <p className="leading-relaxed font-light">{industry.content}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Industries
