import React from "react";

import ReactGA from 'react-ga4'


import Hero from "../components/Home/Hero";
import D2D from "../components/Home/D2D";
import Kiota from "../components/Home/Kiota";
import Tumbler from "../components/Home/Tumbler"
import HomeIt from "../components/Home/HomeIt"
import Gach from "../components/Home/Gach"
import Grace from "../components/Home/Grace"
import CallToAction from "../components/Home/CallToAction"
import Services from "../components/Home/Services";
import Industries from "../components/Home/Industries";
import Clients from "../components/Home/Clients";
import Why from "../components/Home/Why";
import Cta from "../components/Home/Cta";
import Works from "../components/Home/Works";



const Home = () => {
  ReactGA.send({
    hitType: "pageview",
    page: "/",
    title: "Home",
  });


  return (
    <>
      <Hero />
      <D2D />
      <Kiota />
      <Tumbler />
      <HomeIt />
      <Gach />
      <Grace />
      <Services />
      <Works />
      <Industries />
      <Clients />
      <CallToAction />
      <Why />
      <Cta />
    </>
  );
};

export default Home;
