import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Services = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div className='bg-[#FFFFFF]'>
      <section className="container mx-auto md:mx-auto md:container sm:container" data-aos="fade-down">
        <div className="mx-auto p-6">
          <div className="lg:px-16 md:px-12 sm:px-8">
              <div className="py-4 text-5xl md:text-4xl sm:text-5xl font-bold"> Services</div>
          </div>

          <div className="py-4 lg:px-16 md:px-12 sm:px-8">
            <div className='grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 py-6'>
              <div className="rounded-lg bg-[#FFFFFF] md:p-8 p-4 " data-aos="fade-down">
                <div className="flex items-start space-x-4">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    fill="none" viewBox="0 0 24 24"
                    stroke-width="1.5" stroke="currentColor"
                    className="h-10 w-10">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                  </svg>
                  <h2 className="text-xl font-bold text-[#0000EE]">Ideation</h2>
                </div>
                <p className="mt-4 ml-14 text-base font-medium text-[#121212]">
                  We conduct the user research and test ideas, to create a user-friendly solution with the right market fit.
                </p>
              </div>

              <div className="rounded-lg bg-[#FFFFFF] md:p-8 p-4" data-aos="fade-down">
                <div className="flex items-start space-x-4">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                    className="h-10 w-10"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M14.25 9.75 16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
                  </svg>
                  <h2 className="text-xl font-bold text-[#0000EE]">UI|UX Design</h2>
                </div>
                <p className="mt-4 ml-14 text-base font-medium text-[#121212]">
                  We focus on user needs, create UI and UX design and prototypes for web and mobile applications.
                </p>
              </div>
            </div>
          </div>

          <div className="py-4 lg:px-16 md:px-12 sm:px-8">
            <div className='grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 py-6'>
              <div className="rounded-lg bg-[#FFFFFF] md:p-8 p-4" data-aos="fade-down">
                <div className="flex items-start space-x-4">
                  <svg className="h-10 w-10"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                  </svg>
                  <h2 className="text-xl font-bold text-[#0000EE]">Mobile Development</h2>
                </div>
                <p className="mt-4 ml-14 text-base font-medium text-[#121212]">
                  We incoporate the latest technologies to create mobile applications for Android and iOS platforms.
                </p>
              </div>

              <div className="rounded-lg bg-[#FFFFFF] md:p-8 p-4" data-aos="fade-down">
                <div className="flex items-start space-x-4">
                  <svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25" />
                  </svg>
                  <h2 className="text-xl font-bold text-[#0000EE]">Web Development</h2>
                </div>
                <p className="mt-4 ml-14 text-base font-medium text-[#121212]">
                  We implement responsive design and functionality to create attractive websites and web applications.
                </p>
              </div>
            </div>
          </div>
          
          <div className="py-8 flex justify-center">
            <Link to="/services" className="mt-6 px-6 py-2 border-2 border-[#121212] text-[#121212] rounded hover:bg-[#121212] hover:text-white transition">
              More Services
            </Link>
          </div>

        </div>
      </section>
    </div>
  )
}

export default Services