import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import blackbanner from './assets/blackbanner.svg';

const navLinks = [
  { name: 'Services', path: '/services' },
  { name: 'Work', path: '/work' },
  { name: 'About', path: '/about' },
];

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className='fixed top-0 w-full bg-[#FFFFFF] z-10'>
      <div className='py-6 px-8 flex items-center justify-between'>
        <Link to="/">
          <img className="h-8 sm:h-8" src={blackbanner} alt="logo" />
        </Link>
        <div className='hidden md:flex flex-1 justify-center space-x-8 text-xl font-bold'>
          {navLinks.map((link, index) => (
            <Link key={index} className='hover:text-[#0000EE]' to={link.path}>
              {link.name}
            </Link>
          ))}
        </div>
        <div className='hidden md:flex'>
          <Link to="/contact">
            <button className='bg-[#0000EE] text-[#FFFFFF] px-4 py-2 rounded-md hover:bg-indigo-700'>
              Contact Us
            </button>
          </Link>
        </div>
        <button className='md:hidden' onClick={toggleMenu}>
          {menuOpen ? (
            <XMarkIcon className='h-10 w-10' />
          ) : (
            <Bars3Icon className='h-10 w-10' />
          )}
        </button>
      </div>
      {menuOpen && (
        <div className='md:hidden flex flex-col items-center space-y-4 mt-4 text-xl font-bold py-4'>
          {navLinks.map((link, index) => (
            <Link key={index} className='hover:text-[#3F2DC8]' to={link.path} onClick={toggleMenu}>
              {link.name}
            </Link>
          ))}
          <Link to="/contact" onClick={toggleMenu}>
            <button className='bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700'>
              Contact Us
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default Navbar;
