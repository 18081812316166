import { Link } from "react-router-dom";

const AboutCta = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 py-20">
      <div className="py-6">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-[#121212]">
          Contact
        </h2>
      </div>
      <div className="py-4">
        <p className="font-medium sm:text-xl text-[#121212] py-12 text-center">
          Get a free estimate for your project.
        </p>
      </div>
      <Link to='/contact' className="bg-[#121212] text-white font-semibold py-3 px-6 rounded-md hover:bg-gray-900 transition duration-300 inline-block">
        CONTACT US
      </Link>
    </div>
  );
};

export default AboutCta;
