import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import about from '../assets/about.gif'


const MainAbout = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (

        <>
            <div className='lg:py-20 bg-[#FFFFFF]'>
                <section className="container mx-auto">
                    <div className="mx-auto p-6">
                        <div className="lg:px-16 md:px-12 sm:px-8">
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 py-6">
                                <div className="h-96 rounded-lg bg-[#FFFFFF] order-2 lg:order-1">
                                    <div className="p-4">
                                        <h2 className="text-3xl sm:text-4xl md:text-5xl uppercase font-bold text-[#121212]">
                                            App design and development firm.
                                        </h2>
                                    </div>
                                    <div className="p-4" data-aos="fade-up">
                                        <p className="font-medium leading-relaxed">
                                            We transform ideas into innovations. One line of code at a time.
                                            <br />
                                            We are a team of dedicated individuals prioritizing customer and user needs. 
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-center p-4 rounded-lg bg-[#FFFFFF] order-1 lg:order-2">
                                    <img className='h-48 sm:h-64 md:h-80 lg:h-96' src={about} alt="aboutgif" loading="lazy"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <div className="container mx-auto py-20 bg-gray-100" data-aos="fade-down">
                <div className="p-8 text-center">
                    <h2 className="text-2xl  font-bold text-gray-900 leading-loose">
                        About Batian Technologies.
                    </h2>
                </div>
                <div className="px-6 md:px-12 lg:px-24 text-center">
                    <p className="leading-loose">
                        Batian Technologies is a web and mobile app design & development firm.
                        We help clients with end-to-end software development and team extension capabilities.
                    </p>
                </div>

                <div className="flex items-center justify-center py-16" data-aos="fade-down" >
                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 text-center">
                        <div>
                            <h2 className="text-5xl font-bold">3 +</h2>
                            <p className="text-xl font-medium">years on the market</p>
                        </div>
                        <div>
                            <h2 className="text-5xl font-bold">10 +</h2>
                            <p className="text-xl font-medium">products delivered</p>
                        </div>
                        <div>
                            <h2 className="text-5xl font-bold">10</h2>
                            <p className="text-xl font-medium">team members</p>
                        </div>
                    </div>
                </div>


                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8" data-aos="fade-up">
                    <div className="py-12">
                        <div className="p-8 text-center">
                            <h2 className="text-2xl sm:text-4xl font-bold text-gray-900 leading-loose">
                                Mission
                            </h2>
                        </div>
                        <div className="px-6 md:px-12 lg:px-24">
                            <p className="leading-loose">
                                To empower businesses and individuals, provide innovative and customized solutions in web apps, mobile applications, and hardware.
                                We are committed to delivering high-quality, reliable, and user-centric products that enhance efficiency, productivity, and user experiences across various industries.
                            </p>
                        </div>
                    </div>
                    <div className="py-12">
                        <div className="p-8 text-center">
                            <h2 className="text-2xl sm:text-4xl font-bold text-gray-900 leading-loose">
                                Vision
                            </h2>
                        </div>
                        <div className="px-6 md:px-12 lg:px-24">
                            <p className="leading-loose">
                                We aspire to drive digital transformation by creating forward-thinking technologies that empower businesses to thrive in a rapidly evolving digital landscape.
                                Through our innovative products, we aim to positively impact lives and contribute to a smarter, connected, and more inclusive world.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="p-8 text-center">
                    <h2 className="text-2xl sm:text-4xl font-bold text-gray-900 leading-loose">
                        Values
                    </h2>
                </div>
                <div className="px-6 md:px-12 lg:px-24" >
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8" data-aos="fade-down">
                        <div className="h-48 rounded-lg py-2">
                            <h3 className="text-xl font-bold leading-loose">Excellence</h3>
                            <p className="leading-loose text-md">We aim for top quality in all our work, ensuring our products and services meet and exceed client expectations.</p>
                        </div>
                        <div className="h-48 rounded-lg py-2">
                            <h3 className="text-xl font-bold leading-loose">Innovation</h3>
                            <p className="leading-loose text-md">We explore new technologies and trends to create products that benefit our customers.</p>
                        </div>
                        <div className="h-48 rounded-lg py-2">
                            <h3 className="text-xl font-bold leading-loose">Customer Focus</h3>
                            <p className="leading-loose text-md">Our business revolves around our clients, understanding their needs, and providing customized solutions for long-term, trust-based partnerships.</p>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8 py-16" data-aos="fade-up">
                        <div className="h-48 rounded-lg py-2 sm:mb-4">
                            <h3 className="text-xl font-bold leading-loose">Collaboration</h3>
                            <p className="leading-loose text-md">We foster an inclusive and supportive environment that encourages open communication, knowledge sharing, and collaboration.</p>
                        </div>
                        <div className="h-48 rounded-lg py-2">
                            <h3 className="text-xl font-bold leading-loose">Transparency</h3>
                            <p className="leading-loose text-md">We prioritize ethics, maintain confidentiality, and ensure the security and privacy of our clients' data.</p>
                        </div>
                        <div className="h-48 rounded-lg py-2">
                            <h3 className="text-xl font-bold leading-loose">Continuous Improvement</h3>
                            <p className="leading-loose text-md">We appreciate feedback to learn, grow, and improve as individuals and as a company.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default MainAbout