import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';

import ReactGA from 'react-ga4'

import Home from './pages/Home';
import Services from './pages/Services';
import Work from './pages/Work';
import About from './pages/About';
import Contact from './pages/Contact';
import NoPage from './pages/NoPage';
import Privacy from './pages/Privacy';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import CookieConsent from './components/CookieConsent'; // Import the CookieConsent component

import useScrollToTop from './hooks/useScrollToTop';

const ScrollToTop = () => {
  const location = useLocation();
  useScrollToTop(location); // Ensure useScrollToTop hook is correctly implemented

  return null;
};

const App = () => {
  ReactGA.initialize("G-BD1ZCF1ZQM");


  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <main>
        <Routes>
          <Route index element={<Home />} />
          <Route path='/' element={<Home />} />
          <Route path='services' element={<Services />} />
          <Route path='work' element={<Work />} />
          <Route path='about' element={<About />} />
          <Route path='contact' element={<Contact />} />
          <Route path='privacy' element={<Privacy />} />
          <Route path='*' element={<NoPage />} />
        </Routes>
      </main>
      <Footer />
      <CookieConsent /> 
    </BrowserRouter>
  );
}

export default App;
