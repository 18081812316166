import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import kiota from '../assets/kiota.svg'


const KiotaWork = () => {
    useEffect(() => {
        AOS.init({duration: 2000});
      }, []);

  return (
    <>
        <div>
            <section className='bg-[#03045E] py-20' data-aos = "fade-down">
                <div className="container mx-auto md:mx-auto md:container sm:container">
                    <div className="mx-auto p-8">
                        <div className="lg:px-16 md:px-12 sm:px-8 py-4">
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 py-6">
                                <div className="text-[#F1F1F1]">
                                    <div className="">
                                        <h2 className="lg:text-5xl md:text-5xl sm:text-5xl uppercase font-bold">Kiota</h2>
                                    </div>
                                    <div className="py-2">
                                        <p className="font-medium text-xl leading-normal">FinTech mobile app.</p>
                                    </div>
                                    <div className="py-6">
                                        <p className="font-medium leading-relaxed">
                                        Kiota Mobile App enables users establish and track financial goals. <br />
                                        Provides access to saving for those without access to conventional financial services.                                        </p>
                                    </div>
                                    <div className="py-6 font-bold uppercase leading-relaxed flex justify-start">
                                        <div className="py-2 mr-2">
                                            Fintech
                                        </div>
                                        <div className="py-2 mr-2">
                                            EIDV
                                        </div>
                                        <div className="py-2 mr-2">
                                            Neobank
                                        </div>
                                    </div>
                                    <div className="py-4">
                                        <button className='py-2 px-4 border border-lg border-[#FFFFFF] text-[#FFFFFF] hover:bg-[#FFFFFF] hover:text-[#03045E]' > More Details </button>
                                    </div>
                                </div>
                                <div className="rounded-lg">
                                    <div className="p-4 grid items-center">
                                        <img className='h-96 w-96 rounded-md hover:shadow-lg' src={kiota} alt="kiota" loading="lazy"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </section>
        </div>
    </> 
    
  )
}

export default KiotaWork