import React from 'react'

import ReactGA from 'react-ga4'

import HeroWork from '../components/Work/HeroWork'
import D2DWork from '../components/Work/D2DWork'
import KiotaWork from '../components/Work/KiotaWork'
import TumblerWork from '../components/Work/TumblerWork'
import HomeItWork from '../components/Work/HomeItWork'
import GachWork from '../components/Work/GachWork'
import GraceWork from '../components/Work/GraceWork'
import CtaWork from '../components/Work/CtaWork'
import Process from '../components/Work/Process'

const Work = () => {

  ReactGA.send({
    hitType: "pageview",
    page: "/work",
    title: "Work",
  });

  return (
    <>
      <HeroWork />
      <D2DWork />
      <KiotaWork />
      <TumblerWork />
      <HomeItWork />
      <GachWork />
      <GraceWork />
      <Process />
      <CtaWork />
    </>
  )
}

export default Work