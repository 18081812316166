import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import agile from '../assets/agile.png'
import customer from '../assets/customer.png'
import product from '../assets/product.png'
import transparent from '../assets/transparent.png'



const Approach = () => {
  useEffect(() => {
    AOS.init({duration: 1000});
  }, []);

  return (
    <>
    <div className='bg-[#FFFFFF]'>
      <section className="container mx-auto md:mx-auto md:container sm:container" data-aos = "fade-down">
        <div className="mx-auto p-6">
            <div className="lg:px-16 md:px-12 sm:px-8">
              <div className="py-2">
                <div className="py-6 text-3xl md:text-3xl sm:text-xl font-bold">Our approach</div>
              </div>
            </div>

            <div className="py-4 lg:px-16 md:px-12 sm:px-8">
              <div className='grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 py-6'>
                <div className="rounded-lg bg-[#FFFFFF] md:p-8 p-4 " data-aos = "fade-down">
                  <div className="flex items-start space-x-4">
                    <img className='h-10 w-10' src= {customer} alt="customer" loading="lazy"/>
                    <h2 className="text-xl font-bold uppercase text-[#121212]">Customer centric</h2>
                  </div>
                  <p className="mt-4 ml-14 text-base font-medium text-[#121212]">
                  Development with a focus
                  on the user.
                  </p>
                </div>

                <div className="rounded-lg bg-[#FFFFFF] md:p-8 p-4" data-aos = "fade-down">
                  <div className="flex items-start space-x-4">
                    <img className='h-10 w-10' src= {agile} alt="agile" loading="lazy"/>
                    <h2 className="text-xl font-bold uppercase text-[#121212]">Agile</h2>
                  </div>
                  <p className="mt-4 ml-14 text-base font-medium text-[#121212]">
                  An iterative approach
                  to achieve the best results.
                  </p>
                </div>
              </div>
            </div>

            <div className="py-4 lg:px-16 md:px-12 sm:px-8">
              <div className='grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 py-6'>
                <div className="rounded-lg bg-[#FFFFFF] md:p-8 p-4" data-aos = "fade-down">
                  <div className="flex items-start space-x-4">
                  <img className='h-10 w-10' src= {product} alt="product" loading="lazy"/>
                  <h2 className="text-xl font-bold uppercase text-[#121212]">Product mindset</h2>
                  </div>
                  <p className="mt-4 ml-14 text-base font-medium text-[#121212]">
                  We treat your solutions
                  as our own.
                  </p>
                </div>

                <div className="rounded-lg bg-[#FFFFFF] md:p-8 p-4" data-aos = "fade-down">
                  <div className="flex items-start space-x-4">
                  <img className='h-10 w-10' src= {transparent} alt="transparent" loading="lazy"/>
                  <h2 className="text-xl font-bold uppercase text-[#121212]">Transparent</h2>
                  </div>
                  <p className="mt-4 ml-14 text-base font-medium text-[#121212]">
                  Full transparency over
                  development process.
                  </p>
                </div>
              </div>
            </div>
        </div>
      </section>
    </div>
    </>
)
}

export default Approach