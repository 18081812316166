import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import services from "../assets/services.gif";

import {
    MdOutlineDesktopWindows,
    MdOutlineScreenshot,
    MdOutlineCode,
    MdOutlineAutoAwesomeMosaic,
} from "react-icons/md";

const HeroServices = () => {
    useEffect(() => {
        AOS.init({ duration: 2000 });
    }, []);

    return (
        <>
            <div className="lg:py-20 bg-[#FFFFFF]">
                <section className="container mx-auto">
                    <div className="mx-auto p-6">
                        <div className="lg:px-16 md:px-12 sm:px-8">
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 py-6">
                                <div className="h-96 rounded-lg bg-[#FFFFFF] order-2 lg:order-1">
                                    <div className="p-4">
                                        <h2 className="text-2xl sm:text-4xl md:text-4xl uppercase font-bold text-[#121212]">
                                            Mobile App, Web App, and Custom Software Development
                                            Services.
                                        </h2>
                                    </div>
                                    <div className="p-4" data-aos="fade-up">
                                        <p className=" font-medium leading-relaxed">
                                            We help design, create, and develop ideas for individual
                                            and corporate clients. <br />
                                            We offer the necessary expertise to develop solutions that
                                            meet business goals and users’ needs.
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-center p-4 rounded-lg bg-[#FFFFFF] order-1 lg:order-2">
                                    <img
                                        className="h-48 sm:h-64 md:h-80 lg:h-96"
                                        src={services}
                                        alt="Servicesgif"
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="container mx-auto">
                    <div className="mx-auto p-6">
                        <div className="lg:px-16 md:px-12 sm:px-8">
                            <div className="py-2">
                                <div className="rounded-lg bg-[#FFFFFF] order-2 lg:order-1">
                                    <div className="px-8 ">
                                        <h2 className="text-2xl sm:text-4xl md:text-4xl font-bold text-[#121212] space-y-2 py-4 leading-loose">
                                            Our Services:
                                        </h2>
                                    </div>
                                    <div
                                        className="py-6 sm:py-4 grid grid-cols-1 gap-4 lg:grid-cols-4 lg:gap-8 p-8 md:grid-cols-2 md:gap-8 sm:grid-cols-2 sm:gap-4 font-semibold"
                                        data-aos="fade-up"
                                    >
                                        <div className=" rounded-lg ">
                                            <div className=" grid justify-center">
                                                <MdOutlineScreenshot className="h-16 w-16" />
                                            </div>
                                            <div className="py-6 grid justify-center">
                                                Mobile App Development
                                            </div>
                                        </div>
                                        <div className="rounded-lg ">
                                            <div className=" grid justify-center">
                                                <MdOutlineDesktopWindows className="h-16 w-16" />
                                            </div>
                                            <div className="py-6 grid justify-center">
                                                Web App Development
                                            </div>
                                        </div>
                                        <div className="rounded-lg  ">
                                            <div className=" grid justify-center">
                                                <MdOutlineAutoAwesomeMosaic className="h-16 w-16" />
                                            </div>
                                            <div className="py-6 grid justify-center">
                                                UI|UX Design
                                            </div>
                                        </div>
                                        <div className="rounded-lg ">
                                            <div className=" grid justify-center">
                                                <MdOutlineCode className="h-16 w-16" />
                                            </div>
                                            <div className="py-6 grid justify-center">
                                                Custom Software
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default HeroServices;
