import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'


import d2d from '../assets/d2d.svg'
import kiotawhite from '../assets/kiotawhite.svg'
import gracelogo from '../assets/gracelogo.svg'
import gachwhite from '../assets/gachwhite.svg'
import tumblerwhite from '../assets/tumblerwhite.svg'
import homeit from '../assets/homeit.svg'






const Clients = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);


  return (
    <>
      <div className='bg-[#FFFFFF]'>
        <section className="container pt-20 mx-auto md:mx-auto md:container sm:container" data-aos="fade-down">
          <div className="mx-auto p-6">
            <div className="lg:px-16 md:px-12 sm:px-8">
              <div className="py-2">
                <div className="py-4 text-5xl md:text-4xl sm:text-5xl font-bold"> Clients</div>
              </div>
            </div>
          </div>
        </section>

        <div className="py-4 lg:px-16 md:px-12 sm:px-8">
          <div className='grid justify-items-center grid-cols-1 gap-4 lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-2 lg:gap-8 md:gap-6 p-6' data-aos="fade-down">
            <img className='size-60 rounded-md ' src={gracelogo} alt="grace" loading="lazy"/>
            <img className='size-60 rounded-md ' src={d2d} alt="d2d" loading="lazy"/>
            <img className='size-60 rounded-md ' src={kiotawhite} alt="kiotawhite" loading="lazy"/>
            <img className='size-60 rounded-md ' src={homeit} alt="homeit" loading="lazy"/>
            <img className='size-60 rounded-md ' src={gachwhite} alt="gach" loading="lazy"/>
            <img className='size-60 rounded-md ' src={tumblerwhite} alt="tumbler" loading="lazy"/>
          </div>
        </div>
      </div>
      <div className="py-8 flex justify-center">
        <Link to="/work" className="mt-6 px-6 py-2 border-2 border-[#121212] text-[#121212] rounded hover:bg-[#121212] hover:text-white transition">
          Portfolio 
        </Link>
      </div>
    </>
  )
}

export default Clients