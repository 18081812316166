import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import tumbler from '../assets/tumbler.svg'


const Tumbler = () => {
    useEffect(() => {
        AOS.init({duration: 2000});
      }, []);
      
  return (
    <>
        <div>
            <section className='bg-[#FF4000] py-20' data-aos = "fade-down">
                <div className="container mx-auto md:mx-auto md:container sm:container">
                    <div className="mx-auto p-8">
                        <div className="lg:px-16 md:px-12 sm:px-8 py-4">
                            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 py-6">
                                <div className="text-[#F1F1F1]">
                                    <h2 className="lg:text-5xl md:text-5xl sm:text-5xl uppercase font-bold">Tumbler</h2>
                                    <div className="py-2">
                                        <p className="font-medium text-xl leading-normal">Drinks and refreshments web app.</p>
                                    </div>
                                    <div className="py-6">
                                        <p className="font-medium leading-relaxed">
                                            Tumbler is a direct-to-order drink and refreshments application. <br />
                                            Users can create tabs and enjoy discounts for delivered drinks.
                                        </p>
                                    </div>
                                    <div className="py-6 font-bold uppercase leading-relaxed flex justify-start">
                                        <div className="py-2 mr-2">
                                            Drinks
                                        </div>
                                        <div className="py-2 mr-2">
                                            ODS
                                        </div>
                                        <div className="py-2 mr-2">
                                            Web App
                                        </div>
                                    </div>
                                </div>
                                <div className="rounded-lg">
                                    <div className="p-4 grid items-center">
                                        <img className='h-96 w-96 rounded-md hover:shadow-lg' src={tumbler} alt="tumbler" loading="lazy"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </section>
        </div>
    </>
  )
}

export default Tumbler