import React from 'react'

const Privacy = () => {
  return (
    <>
      <div className="py-20 bg-[#FFFFFF]">
        <section className="container mx-auto">
          <div className="mx-auto p-6">
            {/* Intro to Privacy Policies */}
            <div className="px-4 sm:px-8 md:px-12 lg:px-16 py-6">
              <div className="p-4">
                <h2 className="text-2xl sm:text-3xl md:text-4xl uppercase font-bold text-[#121212]">
                  Batian Technologies <br />
                  Privacy Policy, Terms and Conditions
                </h2>
              </div>
              <div className="px-4">
                <p className="py-4 leading-relaxed">
                  This Privacy Policy describes how personal information is collected, used, and shared when you visit batiantechnologies.com (the “Website”), applicable to all visitors of this Website, including those from Kenya. By visiting and using the Website, you must agree to this document, which can be found at the bottom of this page.
                </p>
                <p className="py-4 leading-relaxed">
                  This Website collects some Personal Data from its Users.
                </p>
                <p className="py-4 leading-relaxed">
                  Users may be subject to different protection standards, and broader standards may, therefore, apply to some. To learn more about the protection criteria, Users can refer to the applicability section.
                </p>
              </div>
            </div>
            {/* Owner Details */}
            <div className="lg:px-16 md:px-12 sm:px-8 px-4 py-6">
              <div className="p-4">
                <h2 className="text-xl sm:text-3xl font-bold text-[#121212]">
                  Owner & <br />
                  Data Controller
                </h2>
              </div>
              <div className="px-4">
                <p className="leading-relaxed">
                  Batian Technologies, Kibiku, Nairobi, Kenya
                  <br />
                </p>
                <p className="py-4 leading-relaxed">
                  <span className="py-2 font-semibold">
                    Contact Email:
                    <a href="mailto:info@batiantechnologies.com" className="text-[#0000EE]"> info@batiantechnologies.com </a>
                  </span>
                  <br />
                </p>
                <p className="py-4 leading-relaxed">
                  <span className="font-semibold">
                    Latest Update:
                  </span>
                  <span className="mr-2"> June 15, 2024 </span>
                  <br />
                </p>
              </div>
            </div>
            {/* Type of Data Collected */}
            <div className="lg:px-16 md:px-12 sm:px-8 px-4 py-6">
              <div className="p-4">
                <h2 className="text-xl sm:text-3xl font-bold text-[#121212]">
                  Type of <br />
                  Data Collected
                </h2>
              </div>
              <div className="px-4">
                <p className="leading-relaxed">
                  Among the types of Personal Data that this Website collects, by itself or through third parties, there are Cookies, Usage Data,
                  unique device identifiers for advertising (Google Advertiser ID or IDFA, for example), email address, phone number, first name,
                  last name, company name, and country.
                  <br />
                </p>
                <p className="py-4 leading-relaxed">
                  Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.
                  Personal Data may be freely provided by the User, or, in the case of Usage Data, collected automatically when using this Website.
                  Unless specified otherwise, all Data requested by this Website is mandatory and failure to provide this Data may make it impossible for this Website to provide its services. In cases where this Website specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.
                  Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.
                  <br />
                  Any use of Cookies – or of other tracking tools – by this Website or by the owners of third-party services used by this Website serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.
                  Users are responsible for any third-party Personal Data obtained, published or shared through this Website and confirm that they have the third party's consent to provide the Data to the Owner.
                </p>
              </div>
            </div>
            {/* Data Processing */}
            <div className="lg:px-16 md:px-12 sm:px-8 px-4 py-6">
              <div className="p-4">
                <h2 className="text-xl sm:text-3xl font-bold text-[#121212]">
                  Place & Mode of <br />
                  Data Processing
                </h2>
              </div>
              <div className="px-4">
                <span className="font-bold py-2">Legal basis of Data Processing</span>
                <p className="leading-relaxed">
                  The Owner may process Personal Data relating to Users if one of the following applies:
                  <br />
                  – Users have given their consent for one or more specific purposes. Note: Under some legislation, the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to Kenyan data protection law;
                  <br />
                  – provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;
                  <br />
                  – processing is necessary for compliance with a legal obligation to which the Owner is subject;
                  <br />
                  – processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;
                  <br />
                  – processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.
                  <br />
                  In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
                </p>
              </div>
              <div className="px-4 py-2">
                <span className="font-bold py-2">Methods of Data Processing</span>
                <p className="py-2 leading-relaxed">
                  The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data. Data processing is carried out using computers and/or IT-enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Website (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.
                </p>
              </div>
              <div className="px-4">
                <p className="leading-relaxed">
                  The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located. Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.
                </p>
                <p className="py-4 leading-relaxed">
                  If broader protection standards are applicable, Users are also entitled to learn about the legal basis of Data transfers to a country outside Kenya or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data. If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.
                </p>
              </div>
              <div className="px-4 py-2">
                <span className="font-bold py-2">Retention time</span>
                <p className="py-2 leading-relaxed">
                  Personal Data shall be processed and stored for as long as required by the purpose they have been collected for. Therefore:
                  <br />
                  – Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.
                  <br />
                  – Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.
                  <br />
                  The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon the order of an authority.
                  <br />
                  Once the retention period expires, Personal Data shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after the expiration of the retention period.
                </p>
              </div>
            </div>
            {/* Purpose of Processing */}
            <div className="lg:px-16 md:px-12 sm:px-8 px-4 py-6 bg-[#FFFFFF]">
              <div className="p-4">
                <h2 className="text-xl sm:text-3xl md:text-3xl font-bold text-[#121212]">
                  Purpose of <br />
                  Data Collection and Processing
                </h2>
              </div>
              <div className="px-4 mt-4">
                <p className="leading-relaxed text-gray-700">
                  The Data concerning the User is collected to allow the Owner to provide its Services, as well as for the following purposes: Analytics, Advertising, User database management, Managing contacts and sending messages, and Contacting the User.
                </p>
                <p className="py-4 leading-relaxed text-gray-700">
                  Users can find further detailed information about such purposes of processing and about the specific Personal Data used for each purpose in the respective sections of this document.
                </p>
              </div>
            </div>
            {/* Personal Data */}
            <div className="lg:px-16 md:px-12 sm:px-8 px-4 py-6">
              <div className="p-4">
                <h2 className="text-xl sm:text-3xl md:text-3xl font-bold text-[#121212]">
                  Insight into the <br />
                  Processing of Personal Data Collected
                </h2>
              </div>
              <div className="px-4">
                <p className="leading-relaxed">
                  Personal Data is collected for the following purposes and using the following services:
                </p>
                <div className="py-2">
                  <div className="py-4">
                    <div className="font-bold">Analytics</div>
                    <p>
                      The services contained in this section enable the Owner to monitor and analyze web traffic and
                      can be used to keep track of User behavior.
                    </p>
                  </div>
                  <div className="font-bold py-4">
                    Google Analytics
                  </div>
                  <p className="py-2 leading-relaxed">
                    Google Analytics is a web analysis service provided by Google LLC (“Google”). Google utilizes the Data
                    collected to track and examine the use of this Website, to prepare reports on its activities and share
                    them with other Google services.
                    <br />
                    Google may use the Data collected to contextualize and personalize the ads of its own advertising network.
                    Personal Data collected: Cookies and Usage Data.
                    Place of processing: United States – Privacy Policy – Opt Out. Privacy Shield participant.
                  </p>
                  <div className="py-4">
                    <div className="font-bold">Kenya-Specific Compliance</div>
                    <p>
                      This website adheres to Kenya's Data Protection Act, 2019, ensuring the privacy and protection of user data.
                      <br />
                      Personal Data collected: Name, Email, Phone Number, Usage Data.
                      <br />
                      Place of processing: Kenya – Privacy Policy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Contacting User */}
            <div className="px-4 sm:px-8 md:px-12 lg:px-16 py-6">
              <div className="p-4">
                <h2 className="text-xl sm:text-3xl md:text-3xl font-bold text-[#121212]">
                  Contacting the Website User
                  <br />
                </h2>
              </div>
              <div className="font-bold py-2 px-4 ">
                Contact Form - Batian Technologies
              </div>
              <div className="px-4 py-2">
                <p className="leading-relaxed">
                  By filling in the contact form with their Data, the User authorizes this Website to use these details to reply
                  to requests for information, quotes or any other kind of request as indicated by the form’s header.
                  Personal Data collected: email address, first name, and last name.
                  <br />
                </p>
                <span className="font-bold py-2">
                  Messaging and Contact Management
                </span>
                <div className="py-2">
                  <p className="leading-relaxed">
                    This type of service makes it possible to manage a database of email contacts, phone contacts or any other contact
                    information to communicate with the User.
                    These services may also collect data concerning the date and time when the message was viewed by the User,
                    as well as when the User interacted with it, such as by clicking on links included in the message.
                    <br />
                  </p>
                </div>
                <span className="font-bold py-2 block">
                  Email Management - MailChimp
                </span>
                <div className="py-2">
                  <p className="leading-relaxed">
                    MailChimp is an email address management and message sending service provided by The Rocket Science Group, LLC.
                    Personal Data collected: company name, country, email address, first name, and last name.
                    Place of processing: United States – Privacy Policy. Privacy Shield participant.
                    <br />
                  </p>
                </div>
              </div>
              <div className="p-4">
                <div className="text-lg sm:text-xl md:text-2xl font-bold text-[#121212] py-2">
                  Connecting with Us in Kenya
                  <br />
                </div>
                <p className="leading-relaxed">
                  Batian Technologies is proudly based in Kenya, a country renowned for its innovation and vibrant tech ecosystem.
                  We are committed to leveraging local talent and resources to deliver world-class technology solutions.
                  <br />
                </p>
                <p className="leading-relaxed">
                  For any inquiries or support, feel free to reach out through our contact form or visit our office in Nairobi.
                  We are dedicated to providing prompt and personalized assistance to all our clients.
                  <br />
                </p>
              </div>
            </div>
            {/* Database Management */}
            <div className="px-4 py-6 sm:px-8 md:px-12 lg:px-16">
              <div className="p-4">
                <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-[#121212]" >
                  User Database Management
                </h2>
              </div>
              <div className="px-4">
                <p className="leading-relaxed">
                  This type of service allows the Owner to build user profiles by starting from an email address, a personal name, or other information that the User provides to this Website, as well as to track User activities through analytics features. This Personal Data may also be matched with publicly available information about the User (such as social networks' profiles) and used to build private profiles that the Owner can display and use for improving this Website. Some of these services may also enable the sending of timed messages to the User, such as emails based on specific actions performed on this Website.
                </p>
                <span className="font-bold py-2 block">
                  HubSpot CRM
                </span>
                <div className="py-2">
                  <p className="leading-relaxed">
                    HubSpot CRM is a User database management service provided by HubSpot, Inc. Personal Data collected: email address, phone number, and various types of Data as specified in the privacy policy of the service. Place of processing: United States – Privacy Policy.
                  </p>
                </div>
              </div>
            </div>
            {/* Advertising */}
            <div className="lg:px-16 md:px-12 sm:px-8 px-4 py-6">
              <div className="p-4">
                <h2 className="text-xl sm:text-3xl md:text-3xl font-bold text-[#121212]">
                  Marketing and Advertisement
                </h2>
              </div>
              <div className="px-4">
                <p className="leading-relaxed">
                  This type of service allows User Data to be utilized for advertising communication purposes displayed in the form of banners and other advertisements on this Website, possibly based on User interests.
                  <br />
                  This does not mean that all Personal Data is used for this purpose. Information and conditions of use are shown below. Some of the services listed below may use Cookies to identify Users or they may use the behavioral retargeting technique, i.e. displaying ads tailored to the User’s interests and behavior, including those detected outside this Website. For more information, please check the privacy policies of the relevant services.
                  In addition to any opt-out offered by any of the services below, the User may opt out of a third-party service's use of cookies by visiting the Network Advertising Initiative opt-out page.
                  <br />
                </p>
                <div className="font-bold py-4">Facebook Audience Network</div>
                <div className="py-2">
                  <p className="leading-relaxed">
                    Facebook Audience Network is an advertising service provided by Facebook, Inc. In order to understand Facebook's use of Data, consult Facebook's data policy.
                    This Website may use identifiers for mobile devices (including Android Advertising ID or Advertising Identifier for iOS, respectively) and technologies similar to cookies to run the Facebook Audience Network service. One of the ways Audience Network shows ads is by using the User's ad preferences. The User can control this in the Facebook ad settings.
                    Users may opt-out of certain Audience Network targeting through applicable device settings, such as the device advertising settings for mobile phones or by following the instructions in other Audience Network related sections of this privacy policy, if available.
                    Personal Data collected: Cookies, unique device identifiers for advertising (Google Advertiser ID or IDFA, for example) and Usage Data.
                    Place of processing: United States – Privacy Policy – Opt Out. Privacy Shield participant.
                    <br />
                  </p>
                </div>
              </div>
            </div>
            {/* User Rights */}
            <div className="lg:px-16 md:px-12 sm:px-8 px-4 py-6">
              <div className="p-4">
                <h2 className="text-xl sm:text-3xl md:text-3xl font-bold text-[#121212]">
                  Website User Rights
                </h2>
              </div>
              <div className="px-4">
                <p className="leading-relaxed">
                  Users may exercise certain rights regarding their Data processed by the Owner.
                  Users entitled to broader protection standards may exercise any of the rights described below.
                  In all other cases, Users may inquire with the Owner to find out which rights apply to them.
                  <br />
                  In particular, Users have the right to do the following:
                  <br />
                </p>
                <div className="font-bold py-4">The right to object to the processing</div>
                <div className="py-2">
                  <p className="leading-relaxed">
                    Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.
                    <br />
                    Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.
                    <br />
                  </p>
                </div>
                <div className="font-bold py-2">Exercising the rights</div>
                <div className="py-2">
                  <p className="leading-relaxed">
                    Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document.
                    These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.
                    <br />
                  </p>
                </div>
              </div>
            </div>
            {/* Broader Protection */}
            <div className="lg:px-16 md:px-12 sm:px-8 px-4 py-6">
              <div className="p-4">
                <h2 className="text-xl sm:text-3xl md:text-3xl font-bold text-[#121212]">
                  Broader Protection Standards
                </h2>
              </div>
              <div className="px-4">
                <p className="leading-relaxed">
                  While most provisions of this document concern all Users, some provisions expressly only apply if the processing of Personal Data is subject to broader protection standards.
                  <br />
                  Such broader protection standards apply when the processing:
                </p>
                <p className="py-4 leading-relaxed">
                  – is performed by an Owner based within Kenya;
                  <br />
                  – concerns the Personal Data of Users who are in Kenya and is related to the offering of paid or unpaid goods or services to such Users;
                  <br />
                  – concerns the Personal Data of Users who are in Kenya and allows the Owner to monitor such Users’ behavior taking place in Kenya.
                  <br />
                  <br />
                  Any use of Cookies – or of other tracking tools – by this Website or by the owners of third-party services used by this Website serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.
                  Users are responsible for any third-party Personal Data obtained, published, or shared through this Website and confirm that they have the third party's consent to provide the Data to the Owner.
                </p>
              </div>
            </div>
            {/* More Information*/}
            <div className="lg:px-16 md:px-12 sm:px-8 px-4 py-6">
              <div className="p-4">
                <h2 className="text-xl sm:text-3xl md:text-3xl font-bold text-[#121212]">
                  More Insight into Data Collection and Processing
                </h2>
              </div>
              <div className="py-2 px-4">
                <div className="font-bold py-2">Legal Action</div>
                <p className="leading-relaxed">
                  The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Website or the related Services.
                  <br />
                  The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.
                  <br />
                </p>
                <div className="font-bold py-4">The right to object to the processing</div>
                <div className="py-2">
                  <p className="leading-relaxed">
                    In addition to the information contained in this privacy policy, this Website may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.
                    <br />
                  </p>
                </div>
                <span className="font-bold py-2">System Maintenance</span>
                <div className="py-2">
                  <p className="leading-relaxed">
                    For operation and maintenance purposes, this Website and any third-party services may collect files that record interaction with this Website (System logs) use other Personal Data (such as the IP Address) for this purpose.
                    <br />
                  </p>
                </div>
                <span className="font-bold py-2">Information not contained in this policy</span>
                <div className="py-2">
                  <p className="leading-relaxed">
                    More details concerning the collection or processing of Personal Data may be requested from the Owner at any time.
                    Please see the contact information at the beginning of this document.
                    <br />
                  </p>
                </div>
                <span className="font-bold py-2">How “Do Not Track” requests are handled</span>
                <div className="py-2">
                  <p className="leading-relaxed">
                    This Website does not support “Do Not Track” requests.
                    To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.
                    <br />
                    It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.
                    Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required.
                    <br />
                  </p>
                </div>
                <span className="font-bold py-2">Changes to this privacy policy</span>
                <div className="py-2">
                  <p className="leading-relaxed">
                    The Owner reserves the right to make changes to this privacy policy at any time by giving notice to its Users
                    on this page and possibly within this Website and/or - as far as technically and legally feasible -
                    sending a notice to Users via any contact information available to the Owner.
                    <br />
                    It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.
                    Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new
                    consent from the User, where required.<br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}


export default Privacy